import Login from "components/userAccount/loginPage";
import React from "react";
import Layout from "components/layout";
import Seo from "components/seo";

function index() {
  return (
    <Layout>
      <Seo title="Login - User" slug="/user/auth/login" />
      <Login />
    </Layout>
  );
}

export default index;
